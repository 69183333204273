var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mobile-menu", class: { "is-visible": _vm.isVisible } },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "mobile-menu__wrapper" }, [
          _c(
            "ul",
            { staticClass: "mobile-menu__nav nav nav-burger menu" },
            _vm._l(_vm.menu, function (item) {
              return _c(
                "menu-item",
                {
                  key: item.term_id,
                  attrs: {
                    item: item,
                    level: "first",
                    "has-children": item.classes.includes(_vm.catalogClass),
                  },
                },
                [
                  item.classes.includes(_vm.catalogClass)
                    ? [
                        _c(
                          "ul",
                          { staticClass: "subnav" },
                          _vm._l(_vm.menuInner, function (itemInner) {
                            return _c("menu-item", {
                              key: itemInner.term_id,
                              attrs: {
                                item: itemInner,
                                level: "second",
                                hasLine: true,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mobile-menu__phones" },
            _vm._l(_vm.phones, function (item) {
              return item.phone
                ? _c(
                    "a",
                    {
                      staticClass: "header__phone",
                      attrs: { href: _vm.phoneLink(item.phone) },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "icon icon_phone mobile" },
                        [_c("phone-svg")],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "name tablet" }, [
                        _vm._v(_vm._s(item.phone)),
                      ]),
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _vm._v(" "),
          _vm.languages
            ? _c(
                "div",
                { staticClass: "mobile-menu__switches" },
                [_c("lang-switch", { attrs: { languages: _vm.languages } })],
                1
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }