var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "wpm-language-switcher switcher-list" },
    _vm._l(_vm.languages, function (lang) {
      return _c(
        "li",
        {
          class: `${
            lang.current
              ? "item-language-" + lang.code + " is-active"
              : "item-language-" + lang.code
          }`,
        },
        [
          !lang.current
            ? _c("a", { attrs: { href: lang.href, "data-lang": lang.code } }, [
                _c("span", [_vm._v(_vm._s(lang.name))]),
              ])
            : _c("span", { attrs: { "data-lang": lang.code } }, [
                _c("span", [_vm._v(_vm._s(lang.name))]),
              ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }