<template>
  <div class="header__outer">
    <header class="banner header">
      <div class="container">
        <div class="header__wrapper">
          <div class="header__logo" @click="hideMenu">
            <a class="brand" :href="homeUrl" v-if="thisUrl !== homeUrl">
              <logo :url="logo" :alt="siteName"/>
            </a>
            <span class="brand" v-else>
              <logo :url="logo" :alt="siteName"/>
            </span>
          </div>

          <div class="header__menu desktop" v-if="isDesktop && mainMenu">
            <div class="menu-main-menu-container">
              <ul class="nav nav-primary menu">
                <menu-item v-for="item in mainMenu" :key="item.ID" :item="item"
                           :class="item.classes.concat([item.post_name]).join(' ')"
                           :is-highlighted="isDesktop && item.classes.includes(catalogClass) && isCatHighlighted"
                           :has-children="item.menu_order === 2"
                           level="first"
                           @mouseOverHandler="showDropdown(item)"
                           @mouseLeaveHandler="hideDropdown(item)"/>
              </ul>
            </div>
          </div>

          <div class="header__search desktop" v-if="isDesktop" @click="showSearch">
            <div class="icon icon_search">
              <search-svg/>
            </div>
          </div>

          <div class="header__switches desktop" v-if="isDesktop && languages">
            <lang-switch :languages="languages"/>
          </div>

          <div class="header__phones">
            <template v-if="phones.length">
              <a class="header__phone header__phones__main"
                 v-if="phones[0]"
                 :href="phoneLink(phones[0].phone)">
                <span class="icon icon_phone mobile" v-if="isMobile">
                  <phone-svg/>
                </span>

                <template v-else>
                  <span class="name tablet">{{ phones[0].phone }}</span>
                  <i class="fas fa-chevron-down"></i>
                </template>
              </a>

              <div class="header__phones__all" v-if="isDesktop">
                <a v-for="item in phones"
                   :href="phoneLink(item.phone)"
                   class="header__phone"
                   v-if="item.phone" @click="hideMenu">
                  <span class="icon icon_phone mobile" v-if="isMobile">
                    <phone-svg/>
                  </span>
                  <span v-else class="name tablet">{{ item.phone }}</span>
                </a>
              </div>
            </template>

          </div>

          <div class="header__search no-desktop" v-if="!isDesktop" @click="showSearch">
            <div class="icon icon_search">
              <search-svg/>
            </div>
          </div>

          <button data-toggle="menu" title="Menu" class="header__hamburger no-desktop hamburger-icon btn-reset"
                  :class="{'is-active': isMenuVisible}"
                  @click="toggleMenu"
                  v-if="!isDesktop">
              <span class="icon icon_burger" v-if="!isMenuVisible">
                <burger-svg/>
              </span>
            <span class="icon icon_cross" v-else>
                <cross-svg/>
              </span>
          </button>
        </div>
      </div>
    </header>

    <template v-if="isDesktop">
      <dropdown-menu :menu="catMenu" :is-desktop="isDesktop"
                     :left="dropdownLeft"
                     :is-visible.sync="isDropdownVisible"
                     @mouseOverHandler="setHighlighted()"
                     @mouseLeaveHandler="setUnHighlighted()"/>
    </template>

    <template v-if="isMenuVisible">
      <mobile-menu :menu="mainMenu" :menuInner="catMenu" :languages="languages"
                   :is-visible.sync="isMenuVisible"/>
    </template>

    <site-search :is-search-visible="isSearchVisible" @hideSearch="isSearchVisible = false"/>
  </div>
</template>

<script>
import Logo from '../components/Logo';
import MenuItem from '../components/MenuItem';
import LangSwitch from '../components/LangSwitch';
import DropdownMenu from '../components/DropdownMenu';
import MobileMenu from '../components/MobileMenu';
import SiteSearch from '../components/SiteSearch';
import SearchSvg from '../../icons/search.svg';
import PhoneSvg from '../../icons/phone.svg';
import BurgerSvg from '../../icons/burger.svg';
import CrossSvg from '../../icons/cross.svg';

export default {
  name: "PageHeader",
  components: {
    Logo,
    MenuItem,
    LangSwitch,
    DropdownMenu,
    MobileMenu,
    SiteSearch,
    SearchSvg,
    PhoneSvg,
    BurgerSvg,
    CrossSvg
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      isDropdownVisible: false,
      isMenuVisible: false,
      dropdownLeft: 0,
      isCatHighlighted: false,
      isSearchVisible: false,
      catalogClass: 'catalog-menu-item',
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    homeUrl() {
      return this.$store.state.homeUrl;
    },
    thisUrl() {
      return window.location.href.substring(0, window.location.href.length - 1);
    },
    logo() {
      return this.options.logo;
    },
    siteName() {
      return this.$store.state.siteName;
    },
    languages() {
      return this.$store.state.languages;
    },
    phones() {
      return this.options.phones;
    },
    mainMenu() {
      return this.$store.state.menus.main;
    },
    catMenu() {
      return this.$store.state.menus.categories;
    },
  },
  watch: {
    windowWidth(newWidth) {
      this.isMobile = newWidth < 768;
      this.isTablet = newWidth >= 768 && newWidth < 1200;
      this.isDesktop = newWidth >= 1200;
    },
  },
  mounted() {
    this.deviceDetector();
    this.sizes();

    this.$nextTick(() => {
      window.addEventListener('resize', this.sizes);
    })
  },
  methods: {
    sizes() {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.isMobile = this.windowWidth < 768;
      this.isTablet = this.windowWidth >= 768 && this.windowWidth < 1200;
      this.isDesktop = this.windowWidth >= 1200;
    },
    showDropdown(item) {
      if (this.isDesktop && item.classes.includes(this.catalogClass)) {
        this.isDropdownVisible = true;
        this.isHighlighted = true;

        const dropdownParent = this.$el.querySelector(`.${this.catalogClass}`);
        this.dropdownLeft = dropdownParent.getBoundingClientRect().x;
      }
    },
    hideDropdown(item) {
      if (this.isDesktop && item.classes.includes(this.catalogClass)) {
        this.isDropdownVisible = false;
      }
    },
    setHighlighted() {
      this.isCatHighlighted = true;
    },
    setUnHighlighted() {
      this.isCatHighlighted = false;
    },
    toggleMenu() {
      this.isMenuVisible = !this.isMenuVisible;
      this.isMenuVisible
        ? this.scrollHide()
        : this.scrollShow();
    },
    hideMenu() {
      this.isMenuVisible = false;
      this.scrollShow();
    },
    showSearch() {
      this.isSearchVisible = true;
      this.isMenuVisible = false;
    },
    getItemClasses(item) {
      let classes = item.classes;
      classes.push(item.post_name);
      return classes.join(' ');
    },
    phoneLink(phone) {
      let digits = phone.match(/\d+/g);
      let number = digits ? digits.join('') : phone;

      return 'tel:' + number;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.sizes);
  },
}
</script>

<style lang="scss">
@import "../../styles/setup";

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: $c-white;

  .mobile {
    @include bp(768) {
      display: none;
    }
  }

  .tablet {
    display: none;

    @include bp(768) {
      display: block;
    }
  }

  .desktop {
    display: none;

    @include bp(1200) {
      display: block;
    }
  }

  .no-desktop {
    display: block;

    @include bp(1200) {
      display: none;
    }
  }

  &__wrapper {
    padding: 16px 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include bp(768) {
      height: 80px;
      justify-content: flex-end;
    }

    @include bp(1200) {
      padding: 0;
      height: unset;
    }
  }

  &__logo {
    width: 110px;

    @include bp(768) {
      margin-right: auto;
      width: 161.07px;
    }

    @include bp(1200) {
      margin-right: auto;
    }

    @include bp(1440) {
      margin-right: 84px;
    }

    .brand {
      width: 100%;
      display: flex;

      img,
      svg {
        width: 100%;
      }
    }
  }

  &__search {
    cursor: pointer;

    @include bp(1200) {
      margin: 0 auto;
    }

    @include bp(1440) {
      margin: 0 84px 0 auto;
    }
  }

  .icon_phone,
  .icon_search {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;

    @include bp(768) {
      width: 40px;
      height: 40px;
    }

    @include bp(1200) {
      width: 30px;
      height: 30px;
    }

    svg {
      width: 100%;

      path {
        @include bp(1200) {
          fill: $c-black;
        }
      }
    }
  }

  &__phones {
    display: flex;
    flex-direction: column;
    position: relative;

    &__main {
      display: flex;
      align-items: center;

      i {
        margin-left: 10px;
      }
    }

    &__all {
      @include bp(1200) {
        position: absolute;
        top: -15px;
        right: 0;
        background: #ffffff;
        padding: 15px 25px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        //display: none;
        opacity: 0;
        transition: all 0.4s;

        a {
          margin-left: 0;
        }
      }

      a {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }

    @include bp(1200) {
      padding-left: 25px;
      margin: 0 0 0 auto;

      &:hover {
        .header__phones__all {
          //display: block;
          opacity: 1;
        }
      }
    }
  }

  &__phone {
    font-size: 24px;
    line-height: 29px;
    color: $c-blue;
    text-decoration: none;

    @include bp(768) {
      margin: 0 76px;
    }

    @include bp(1200) {
      margin: 0 0 0 auto;
      font-size: 18px;
      line-height: 22px;
    }

    //@include bp(1440) {
    //  margin: 0 0 0 84px;
    //}
  }

  &__hamburger {
    width: 35px;

    @include bp(768) {
      margin-left: 81px;
    }

    .icon {
      margin: 0;
      padding: 0;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: transparent;
      outline: none;
      box-shadow: none;
      border: none;

      &.icon_cross {
        svg {
          width: 28px;
          height: 28px;
        }
      }

      svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &__menu {
    display: none;

    @include bp(1200) {
      display: block;
    }

    .menu {
      display: flex;
      flex-wrap: nowrap;

      & > * + * {
        margin: 0 0 0 32px;
      }

      .item__content {
        display: block;
        padding: 10px 0;
        font-size: 14px;
        line-height: 17px;

        @include bp(1200) {
          padding: 30.5px 0;
          height: 82px;
        }
      }

      .menu-item {
        white-space: nowrap;

        &.is-active,
        &.is-highlighted {
          .name {
            &::before {
              transform: scaleX(1);
            }
          }
        }

        //&.is-active {
        //  & > .item__content {
        //    pointer-events: none;
        //  }
        //}

        .name {
          position: relative;
          display: flex;

          &::before {
            @include pseudo();
            top: 100%;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $c-blue;
            transform: scaleX(0);
            transform-origin: 0 0;
            @include anim();
          }
        }
      }
    }
  }
}
</style>
