var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "callback-form", class: _vm.getClass() },
    [
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "callback-form__wrapper" },
          [
            _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
              !_vm.success
                ? _c(
                    "div",
                    { key: "form", staticClass: "callback-form__form" },
                    [
                      _vm.form.title
                        ? _c("h4", {
                            staticClass: "callback-form__title",
                            domProps: { innerHTML: _vm._s(_vm.form.title) },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("base-form", {
                        attrs: {
                          button: this.Translate("Request a call"),
                          "button-class-name":
                            _vm.specialClass === "white"
                              ? "btn-theme_transparent-blue"
                              : "btn-theme_transparent-white",
                          "is-white":
                            !_vm.specialClass || _vm.specialClass !== "white",
                          "is-blue": _vm.specialClass === "white",
                          "is-row": true,
                          fields: _vm.fields,
                        },
                        on: {
                          success: function ($event) {
                            return _vm.onSuccess()
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "callback-form__text" }, [
                        _vm._v(_vm._s(_vm.policy.text) + " "),
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.privacyPage.url,
                              rel: "nofollow",
                            },
                          },
                          [_vm._v(_vm._s(_vm.policy.link))]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.success
                ? _c(
                    "div",
                    { key: "thanks", staticClass: "callback-form__success" },
                    [
                      _c(
                        "div",
                        { staticClass: "icon icon_check" },
                        [_c("check-svg")],
                        1
                      ),
                      _vm._v(" "),
                      _vm.form.thank_title
                        ? _c("h4", {
                            staticClass: "callback-form__title",
                            domProps: {
                              innerHTML: _vm._s(_vm.form.thank_title),
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("p", {
                        staticClass: "callback-form__text",
                        domProps: { innerHTML: _vm._s(_vm.form.thank_text) },
                      }),
                    ]
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }