var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      class: {
        "item is-active": _vm.isActive,
        item: !_vm.isActive,
        "is-highlighted": _vm.isHighlighted || _vm.isThisHighlighted,
        "menu-item": _vm.level === "first",
        "second-menu-item": _vm.level === "second",
        "third-menu-item": _vm.level === "third",
        "has-children": _vm.hasChildren,
      },
      on: {
        click: _vm.clickHandler,
        mouseover: _vm.mouseOverHandler,
        mouseleave: _vm.mouseLeaveHandler,
      },
    },
    [
      [
        !_vm.hasChildren
          ? _c(
              "a",
              {
                staticClass: "item__content",
                attrs: {
                  href: _vm.item.url
                    ? _vm.item.url
                    : _vm.siteUrl + _vm.item.slug + "/",
                },
              },
              [
                _vm.hasLine
                  ? _c("span", { staticClass: "icon icon_line" })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.item.title || _vm.item.name)),
                ]),
                _vm._v(" "),
                _vm.hasCaret
                  ? _c(
                      "span",
                      { staticClass: "icon icon_caret" },
                      [_c("caret-svg")],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _c(
              "a",
              {
                staticClass: "item__content",
                attrs: {
                  href: _vm.item.url
                    ? _vm.item.url
                    : _vm.siteUrl + _vm.item.slug + "/",
                },
              },
              [
                _vm.hasLine
                  ? _c("span", { staticClass: "icon icon_line" })
                  : _vm._e(),
                _vm._v(" "),
                _c("span", { staticClass: "name" }, [
                  _vm._v(_vm._s(_vm.item.title)),
                ]),
                _vm._v(" "),
                _vm.hasCaret && _vm.hasChildren
                  ? _c(
                      "span",
                      { staticClass: "icon icon_caret" },
                      [_c("caret-svg")],
                      1
                    )
                  : _vm._e(),
              ]
            ),
      ],
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }