<template>
  <fragment>
    <div class="google-map" ref="googleMap"></div>
    <div v-if="!isTouchable" class="zoom-wrap">
      <div class="btn-zoom btn-zoom_in" ref="zoomIn">
        <zoom-in-svg/>
      </div>
      <div class="btn-zoom btn-zoom_out" ref="zoomOut">
        <zoom-out-svg/>
      </div>
    </div>
  </fragment>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';
import ZoomInSvg from '../../icons/zoom-in.svg';
import ZoomOutSvg from '../../icons/zoom-out.svg';
import {mapStyles} from '../store/mapStyles';

export default {
  name: "BaseGmap",
  components: {
    ZoomInSvg,
    ZoomOutSvg
  },
  data() {
    return {
      google: null,
      map: null,
      marker: null,
      mapOptions: {
        zoom: 15,
        gestureHandling: 'cooperative',
        disableDefaultUI: true,
        styles: mapStyles,
      }
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    APIKey() {
      return this.options.map_key;
    },
    centerLat() {
      return this.options.location.lat;
    },
    centerLng() {
      return this.options.location.lng;
    },
    location() {
      return {lat: parseFloat(this.centerLat), lng: parseFloat(this.centerLng)}
    },
    markerIcon() {
      return this.options.marker;
    },
    mapConfig() {
      return {
        ...this.mapOptions,
        center: this.location
      }
    },
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: this.APIKey
    })
    this.google = googleMapApi;
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;

      this.map = new this.google.maps.Map(
        mapContainer, this.mapConfig
      );

      this.marker = new this.google.maps.Marker({
        position: this.location,
        map: this.map,
        icon: this.markerIcon,
      });

      if (this.$refs.zoomIn) {
        this.google.maps.event.addDomListener(
          this.$refs.zoomIn, 'click', () => this.map.setZoom(this.map.getZoom() + 1)
        );
      }
      if (this.$refs.zoomOut) {
        this.google.maps.event.addDomListener(
          this.$refs.zoomOut, 'click', () => this.map.setZoom(this.map.getZoom() - 1)
        );
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/setup";

.google-map {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  display: flex;
  overflow: hidden;
}

iframe {
  position: absolute;
  width: 100%;
  height: 100%;
}

.zoom-wrap {
  position: absolute;
  bottom: 116px;
  right: 16px;
  z-index: 1;
  display: grid;
  grid-template-rows: repeat(2, 50px);
  grid-row-gap: 12px;

  @include bp(1440) {
    bottom: 29px;
  }

  .btn-zoom {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid $c-blue;
    cursor: pointer;

    svg {
      width: 20px;
      height: auto;
    }
  }
}
</style>
