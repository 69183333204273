import Vue from 'vue';

Vue.directive('click-out', {
  /* el, binding, vnode */
  bind: function (el, binding, vnode) {
    document.body.addEventListener('click', (e)=>{
      let path = e.path || e.composedPath();
      let inPath = path.find(item=>item===el);

      if (!inPath) {
        // console.log(inPath);
        if (typeof binding.value === 'function') {
          binding.value.bind(vnode.context)(el, vnode);
        }
      }
    });
  },
});
