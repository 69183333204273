<template>
  <div class="result">
    <div class="result__category" v-if="item.type && item.type !== 'product'">{{item.type}}</div>
    <a :href="item.url" class="result__link" :class="getClass()" >
      <div class="result__content">
        <div class="result__img" v-if="item.image">
          <img :src="item.image" :alt="item.title ? item.title : 'Balenko'">
        </div>
        <div class="result__name" v-if="item.title">{{item.title}}</div>
      </div>
      <div class="result__icon icon icon_arrow">
        <arrow-right-svg />
      </div>
    </a>
  </div>
</template>

<script>
import ArrowRightSvg from '../../icons/arrow-right.svg';

export default {
  name: "SearchResultsItem",
  components: {
    ArrowRightSvg,
  },
  props: {
    item: Object
  },
  created() {
    console.log(this.item)
  },
  methods: {
    getClass() {
      return {
        'has-image': this.item.image && this.item.type !== 'product',
        'has-short-image': this.item.image && this.item.type === 'product',
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../styles/setup";

.result {
  $this: &;

  &__category {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 22px;
    color: $c-dark;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  &__link {
    display: flex;
    align-items: center;

    &.has-image {
      #{$this}__img {
        margin: 4px 0 12px;
        height: 200px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    &.has-short-image {
      #{$this}__content {
        display: grid;
        grid-template-columns: 88.58px 1fr;
        grid-column-gap: 8px;
      }
    }
  }

  &__content {
    flex-grow: 1;
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);

    @include bp(768) {
      padding-bottom: 9px;
    }
  }

  &__img {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  &__name {
    align-self: center;
    font-size: 14px;
    line-height: 22px;
    color: $c-blue;
  }

  &__icon {
    flex: none;
    width: 56px;
    display: flex;

    @include bp(768) {
      width: 52px;
    }

    svg {
      margin-left: auto;
      width: 21px;
      height: 17px;
    }

    path {
      fill: $c-blue;
    }
  }
}
</style>
