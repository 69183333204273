var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "header__outer" },
    [
      _c("header", { staticClass: "banner header" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "header__wrapper" }, [
            _c(
              "div",
              { staticClass: "header__logo", on: { click: _vm.hideMenu } },
              [
                _vm.thisUrl !== _vm.homeUrl
                  ? _c(
                      "a",
                      { staticClass: "brand", attrs: { href: _vm.homeUrl } },
                      [
                        _c("logo", {
                          attrs: { url: _vm.logo, alt: _vm.siteName },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "span",
                      { staticClass: "brand" },
                      [
                        _c("logo", {
                          attrs: { url: _vm.logo, alt: _vm.siteName },
                        }),
                      ],
                      1
                    ),
              ]
            ),
            _vm._v(" "),
            _vm.isDesktop && _vm.mainMenu
              ? _c("div", { staticClass: "header__menu desktop" }, [
                  _c("div", { staticClass: "menu-main-menu-container" }, [
                    _c(
                      "ul",
                      { staticClass: "nav nav-primary menu" },
                      _vm._l(_vm.mainMenu, function (item) {
                        return _c("menu-item", {
                          key: item.ID,
                          class: item.classes
                            .concat([item.post_name])
                            .join(" "),
                          attrs: {
                            item: item,
                            "is-highlighted":
                              _vm.isDesktop &&
                              item.classes.includes(_vm.catalogClass) &&
                              _vm.isCatHighlighted,
                            "has-children": item.menu_order === 2,
                            level: "first",
                          },
                          on: {
                            mouseOverHandler: function ($event) {
                              return _vm.showDropdown(item)
                            },
                            mouseLeaveHandler: function ($event) {
                              return _vm.hideDropdown(item)
                            },
                          },
                        })
                      }),
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isDesktop
              ? _c(
                  "div",
                  {
                    staticClass: "header__search desktop",
                    on: { click: _vm.showSearch },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon icon_search" },
                      [_c("search-svg")],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isDesktop && _vm.languages
              ? _c(
                  "div",
                  { staticClass: "header__switches desktop" },
                  [_c("lang-switch", { attrs: { languages: _vm.languages } })],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "header__phones" },
              [
                _vm.phones.length
                  ? [
                      _vm.phones[0]
                        ? _c(
                            "a",
                            {
                              staticClass: "header__phone header__phones__main",
                              attrs: {
                                href: _vm.phoneLink(_vm.phones[0].phone),
                              },
                            },
                            [
                              _vm.isMobile
                                ? _c(
                                    "span",
                                    { staticClass: "icon icon_phone mobile" },
                                    [_c("phone-svg")],
                                    1
                                  )
                                : [
                                    _c("span", { staticClass: "name tablet" }, [
                                      _vm._v(_vm._s(_vm.phones[0].phone)),
                                    ]),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fas fa-chevron-down",
                                    }),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isDesktop
                        ? _c(
                            "div",
                            { staticClass: "header__phones__all" },
                            _vm._l(_vm.phones, function (item) {
                              return item.phone
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "header__phone",
                                      attrs: {
                                        href: _vm.phoneLink(item.phone),
                                      },
                                      on: { click: _vm.hideMenu },
                                    },
                                    [
                                      _vm.isMobile
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "icon icon_phone mobile",
                                            },
                                            [_c("phone-svg")],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "name tablet" },
                                            [_vm._v(_vm._s(item.phone))]
                                          ),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.isDesktop
              ? _c(
                  "div",
                  {
                    staticClass: "header__search no-desktop",
                    on: { click: _vm.showSearch },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "icon icon_search" },
                      [_c("search-svg")],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isDesktop
              ? _c(
                  "button",
                  {
                    staticClass:
                      "header__hamburger no-desktop hamburger-icon btn-reset",
                    class: { "is-active": _vm.isMenuVisible },
                    attrs: { "data-toggle": "menu", title: "Menu" },
                    on: { click: _vm.toggleMenu },
                  },
                  [
                    !_vm.isMenuVisible
                      ? _c(
                          "span",
                          { staticClass: "icon icon_burger" },
                          [_c("burger-svg")],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "icon icon_cross" },
                          [_c("cross-svg")],
                          1
                        ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.isDesktop
        ? [
            _c("dropdown-menu", {
              attrs: {
                menu: _vm.catMenu,
                "is-desktop": _vm.isDesktop,
                left: _vm.dropdownLeft,
                "is-visible": _vm.isDropdownVisible,
              },
              on: {
                "update:isVisible": function ($event) {
                  _vm.isDropdownVisible = $event
                },
                "update:is-visible": function ($event) {
                  _vm.isDropdownVisible = $event
                },
                mouseOverHandler: function ($event) {
                  return _vm.setHighlighted()
                },
                mouseLeaveHandler: function ($event) {
                  return _vm.setUnHighlighted()
                },
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isMenuVisible
        ? [
            _c("mobile-menu", {
              attrs: {
                menu: _vm.mainMenu,
                menuInner: _vm.catMenu,
                languages: _vm.languages,
                "is-visible": _vm.isMenuVisible,
              },
              on: {
                "update:isVisible": function ($event) {
                  _vm.isMenuVisible = $event
                },
                "update:is-visible": function ($event) {
                  _vm.isMenuVisible = $event
                },
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("site-search", {
        attrs: { "is-search-visible": _vm.isSearchVisible },
        on: {
          hideSearch: function ($event) {
            _vm.isSearchVisible = false
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }