<template>
  <div class="site-search__outer">
    <div class="site-search" :class="{'is-visible': isSearchVisible}">
      <div class="container">
        <div class="site-search__form">
          <label class="input-wrap" :class="{'is-active': noResults || results && results.length}">
            <span class="icon icon_search">
              <search-svg/>
            </span>
            <input type="text"
                   v-model="search" @keyup="getSearchResults" />
            <span class="icon icon_cross" @click="hideSearch">
              <cross-svg/>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="site-search-results" :class="getClass()" v-if="search.length">
      <div class="container">
        <div v-if="results && results.length === 0" class="site-search-results__empty empty">
          {{ getNoResult(search) }}
        </div>

        <div v-if="results && results.length" class="site-search-results__full full"
             :style="{ height: height, maxHeight: maxHeight }">
          <div class="full__title">{{ 'You search' | translate }}:  "{{search}}"</div>
          <search-results :search="search" :results="results"/>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import debounce from 'debounce';
import SearchResults from './SearchResults';
import SearchSvg from '../../icons/search.svg';
import CrossSvg from '../../icons/cross.svg';

export default {
  name: "SiteSearch",
  components: {
    SearchResults,
    SearchSvg,
    CrossSvg,
  },
  props: {
    isSearchVisible: Boolean,
  },
  data() {
    return {
      headerHeight: 0,
      windowHeight: window.innerHeight,
      height: 0,
      maxHeight: window.innerHeight,
      noResults: false,
      hasResults: false,
      search: '',
      results: [],
    }
  },
  mounted() {
    this.sizes();

    this.$nextTick(() => {
      window.addEventListener('resize', this.sizes);
    });
  },
  methods: {
    sizes() {
      this.windowHeight = window.innerHeight;
      if (document) this.headerHeight = document.querySelector('.header').clientHeight;

      this.maxHeight = this.windowHeight + 'px';
      this.noResults
        ? this.height = 'fit-content'
        : this.hasResults
        ? this.height = 'fit-content'
        : this.height = 0;
    },
    hideSearch() {
      this.$emit('hideSearch');
      this.search = '';
      this.results = [];
      this.noResults = false;
      this.hasResults = false;
    },
    getSearchResults: debounce(function () {
      let data = {
        action: 'customSearch',
        search: this.search,
      };

      return this.$store.dispatch('sendRequest', data).then(res => {
        if (res.data.success && res.data.data.posts) {
          // this.results = res.data.data.posts.filter((item) =>
          //   item.title.toLowerCase().includes(data.search.toLowerCase()))

          if (res.data.data.posts.length) {
            this.noResults = false;
            this.hasResults = true;
          } else {
            this.noResults = true;
            this.hasResults = false;
          }

          this.results = res.data.data.posts.filter((item) =>
            item.title.toLowerCase().includes(data.search.toLowerCase()));
          this.sizes();

        } else {
          this.noResults = false;
          this.hasResults = false;
        }
      });
    }, 100),
    getClass() {
      return {
        'noResults': this.noResults,
        'hasResults': this.hasResults,
      }
    },
    getNoResult(search) {
      let format = this.Translate('no_result');

      return this.sprintf(format, search);
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.sizes);
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/setup";

.site-search,
.site-search-results {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  background-color: $c-white;

  //@include bp(1440) {
  //  width: calc(100% - (((100vw - 1440px) / 2) + 311.07px));
  //}

  .container {
    height: 100%;

    @include bp(1440) {
      padding-left: 0;
    }
  }
}

.site-search {
  z-index: 100;
  min-height: 60px;
  transform: scaleX(0);
  transform-origin: 100% 100%;
  @include anim(transform, .3s, ease);

  @include bp(768) {
    min-height: 80px;
  }

  @include bp(1200) {
    min-height: 82px;
  }

  @include bp(1440) {
    width: calc(100% - (((100vw - 1440px) / 2) + 311.07px));
  }

  &.is-visible {
    transform: scaleX(1);

    & > * {
      opacity: 1;
      @include anim(opacity, .3s, ease, .3s);
    }
  }

  & > * {
    opacity: 0;
    @include anim(opacity, 0s, ease);
  }

  &__wrapper,
  &__form {
    height: 100%;
  }

  &__form {
    padding: 16px 0 9px;
    height: 60px;
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);

    @include bp(768) {
      height: 80px;
    }

    @include bp(1200) {
      margin-bottom: 9px;
      padding: 21px 0 9px;
      height: 73px;
    }

    @include bp(1440) {
      margin-right: calc((100vw - 1440px) / 2);
    }
  }

  .input-wrap {
    position: relative;
    padding: 0 30px 0 40px;
    width: 100%;
    height: 100%;

    @include bp(768) {
      padding: 0 54px 0 60px;
    }

    input {
      width: 100%;
      height: 100%;
    }

    .icon {
      position: absolute;
      top: 50%;
      display: flex;
      transform: translateY(-50%);

      svg {
        width: 100%;
        height: 100%;
      }

      &_search {
        left: 0;
        width: 28px;
        height: 28px;

        @include bp(768) {
          width: 36px;
          height: 36px;
        }
      }

      &_cross {
        right: 0;
        width: 18px;
        height: 18px;
        cursor: pointer;

        @include bp(768) {
          width: 30px;
          height: 30px;
        }

        svg {
          path {
            fill: $c-gray-dark;
          }
        }
      }
    }
  }
}

.site-search-results {
  z-index: 99;

  &__empty,
  &__full {
    @include bp(1440) {
      margin-left: auto;
      width: calc(100% - 311.07px);
    }
  }

  &__empty {
    padding: 116px 0 202px;
    font-weight: 600;

    @include bp(768) {
      padding: 133px 0 255px;
    }

    @include bp(1200) {
      padding: 145px 0 189px;
    }
  }

  &__full {
    padding: 116px 0 96px;
    display: flex;
    flex-direction: column;

    @include bp(768) {
      padding: 77px 0 93px;
    }

    @include bp(1200) {
      padding: 64px 0 83px;
    }
  }

  .full {
    &__title {
      margin-bottom: 56px;
      padding-bottom: 8px;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      text-transform: uppercase;
      border-bottom: 1px solid rgba(196, 196, 196, 0.5);

      @include bp(768) {
        padding-top: 32px;
      }

      @include bp(1200) {
        margin-bottom: 32px;
      }
    }
  }
}
</style>
