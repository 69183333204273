<template>
  <section class="callback-form" :class="getClass()">
    <div class="container">
      <div class="callback-form__wrapper">
        <transition name="fade" mode="out-in">
          <div v-if="!success" key="form" class="callback-form__form">
            <h4 class="callback-form__title" v-if="form.title" v-html="form.title"></h4>
            <base-form :button="this.Translate('Request a call')"
                       :button-class-name="specialClass ===  'white' ? 'btn-theme_transparent-blue' : 'btn-theme_transparent-white'"
                       :is-white="!specialClass || specialClass !== 'white'"
                       :is-blue="specialClass ===  'white'"
                       :is-row="true"
                       :fields="fields"
                       v-on:success="onSuccess()"
            ></base-form>

            <p class="callback-form__text">{{ policy.text }} <a :href="privacyPage.url" rel="nofollow">{{ policy.link }}</a></p>
          </div>

          <div v-if="success" key="thanks" class="callback-form__success">
            <div class="icon icon_check">
              <check-svg />
            </div>
            <!--suppress JSUnresolvedVariable -->
            <h4 class="callback-form__title" v-if="form.thank_title" v-html="form.thank_title"></h4>
            <!--suppress JSUnresolvedVariable -->
            <p class="callback-form__text" v-html="form.thank_text"></p>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import BaseForm from "./BaseForm";
import CheckSvg from '../../icons/check.svg';

export default {
  name: "CallBackForm",
  components: {
    BaseForm,
    CheckSvg,
  },
  data() {
    return {
      success: false,
      fields: ['name', 'phone'],
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    form() {
      return this.options.forms.callback || '';
    },
    specialClass() {
      // noinspection JSUnresolvedVariable
      return this.$parent.class;
    },
    policy() {
      return options.forms.policy;
    },
    privacyPage() {
      return this.$store.state.privacyPage;
    },
  },
  methods: {
    getClass() {
      return {
        'has-form': !this.success,
        'has-success': this.success,
        [this.specialClass]: true,
      }
    },
    onSuccess() {
      this.success = true;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push ({
        'event': 'formSuccess',
        'formName': this.$options.name,
      });

      setTimeout(() => {
        this.success = false;
      }, 3000);
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/setup";

.callback-form {
  $this: &;

  color: $c-white;
  background-color: $c-blue-dark;

  &.has-form {
    padding: 60px 0;

    #{$this}__title {
      @include bp(1200) {
        max-width: 995px;
        text-align: left;
      }
    }

    #{$this}__text {
      @include bp(1200) {
        margin: 15px auto 0 0;
        max-width: 666px;
        text-align: left;
      }
    }
  }

  &.has-success {
    padding: 124.5px 0;

    @include bp(768) {
      padding: 183px 0;
    }

    @include bp(1200) {
      padding: 57.5px 0;
    }

    #{$this}__title {
      @include bp(1200) {
        max-width: 666px;
        margin-right: auto;
        margin-left: auto;
      }
    }

    #{$this}__text {
      max-width: 219.16px;
      font-size: 14px;
      line-height: 22px;

      @include bp(768) {
        max-width: 666px;
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &.white {
    color: $c-black;
    background-color: $c-white;
  }

  &__title {
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;

    @include bp(768) {
      font-size: 28px;
      line-height: 45px;
    }

    span {
      color: $c-blue;
    }
  }

  &__text {
    margin: 12px auto 0;
    max-width: 346.52px;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    @include bp(768) {
      font-size: 14px;
      line-height: 17px;
    }

    a,
    span {
      display: inline;
      text-decoration: underline;
      color: $c-blue;
      cursor: pointer;
    }
  }

  .icon_check {
    margin: 0 auto 32px;
    width: fit-content;
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
