<template>
  <div>
    <transition name="fade" mode="out-in">
      <div class="popup__overlay" @click.self="toggle" v-if="show">
        <div class="popup__wrapper">

          <div id="consultation-popup" class="popup-form" ref="popup">
            <div class="popup-form__close js-popup-close" @click="toggle">
              <cross-svg />
            </div>
            <div class="popup-form__wrapper js-popup-wrapper">
              <div class="popup-form__head head">
                <img class="popup-form__img" :src="image" :alt="title">
                <logo-svg />
              </div>

              <transition name="fade" mode="out-in">
                <div v-if="!success" key="form" class="popup-form__form">
                  <p class="popup-form__subtitle" v-html="title"></p>
                  <base-form :button="this.Translate('Request a call')"
                             :button-class-name="`btn-theme_transparent-blue btn-theme_wild`"
                             :is-blue="true"
                             :is-column="true"
                             :fields="fields"
                             v-on:success="onSuccess"
                  ></base-form>

                  <p class="popup-form__text">{{ policy.text }} <a :href="privacyPage.url">{{ policy.link }}</a></p>
                </div>

                <div v-if="success" key="thanks" class="popup-form__success">
                  <!--suppress JSUnresolvedVariable -->
                  <h4 class="popup-form__title" v-if="thank_title" v-html="thank_title"></h4>
                </div>
              </transition>
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import BaseForm from "./BaseForm";
import LogoSvg from '../../icons/logo.svg';
import CrossSvg from '../../icons/cross-white.svg';

export default {
  name: "PopupForm",
  components: {
    BaseForm,
    LogoSvg,
    CrossSvg
  },
  data() {
    return {
      fields: ['name', 'phone'],
      show: false,
      loading: false,
      success: false,
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    formButton() {
      return this.button || this.Translate('Send');
    },
    logo() {
      return this.options.logo;
    },
    image() {
      return this.options.forms.popup.image;
    },
    title() {
      return this.options.forms.popup.title;
    },
    thank_title() {
      return this.options.forms.popup.thank_title;
    },
    policy() {
      return options.forms.policy;
    },
    privacyPage() {
      return this.$store.state.privacyPage;
    },
  },
  mounted() {
    let buttons = document.querySelectorAll('[data-popup=form]');

    buttons.forEach(button => {
      button.addEventListener('click', () => this.toggle());
    });
  },
  methods: {
    toggle() {
      this.show = !this.show;
      this.success = false;

      document.body.classList.toggle('over-hidden');
    },
    onSuccess() {
      this.success = true;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push ({
        'event': 'formSuccess',
        'formName': this.$options.name,
      });

      setTimeout(() => {
        this.toggle();
      }, 3000);
    },
  },
}
</script>

<style lang="scss">
@import "../../styles/setup";

.popup {
  &__wrapper {
    padding: 20px 0;
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 120;
    overflow-y: auto;

    background: rgba(0, 0, 0, 0.5);

    display: flex;
    justify-content: center;
    //align-items: center;
  }
}

.over-hidden {
  overflow: hidden;
}

.popup-form {
  $this: &;

  position: relative;
  width: 100%;
  max-width: calc(100% - 40px);
  margin: 0 auto;

  @include bp(768) {
    max-width: 605px;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
      width: 18px;
      height: auto;
    }
  }

  &__wrapper {
    background-color: $c-white;
  }

  &__head {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 336px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @include bp(768) {
      height: 348px;
    }

    &::after {
      @include pseudo();
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
    }

    img,
    svg {
      width: 267px;
      height: auto;

      &.popup-form__img {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__form {
    padding: 40px 16px 50px;

    @include bp(768) {
      padding: 62px 30px 50px;
    }

    .btn-theme {
      min-width: 100%;
    }
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  &__text {
    margin: 24px auto 0;
    font-size: 12px;
    line-height: 15px;
    text-align: center;

    @include bp(768) {
      margin-top: 12px;
      font-size: 14px;
      line-height: 17px;
    }

    a,
    span {
      display: inline;
      text-decoration: underline;
      color: $c-blue;
      cursor: pointer;
    }
  }

  &__title {
    padding: 42px 16px 36px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    text-align: center;

    @include bp(768) {
      padding: 42px 30px;
    }
  }

  .carousel__button.is-close {
    display: none;
  }
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
