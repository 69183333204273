import Vue from 'vue';

import * as qs from 'qs';
import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

Vue.mixin({
  data() {
    return {
      translations: window.translations,
      ajaxUrl: window.ajaxurl,
    };
  },
  methods: {
    Translate(str) {
      let slug = String(str).toLowerCase().replace(/\s/g, '_');
      let result = [];

      // console.log(this.translations);
      // if (this.translations) {
        result = this.translations.filter(result => result.slug === slug);

        if (!result.length) {
          let data = {
            action: 'addNewTranslation',
            slug: slug.replace('\'', ''),
          };

          if (typeof axios !== 'undefined') {
            console.log('translates');
            Vue.axios.post(this.ajaxUrl, qs.stringify(data));
          }
        }
      // }

      return (result.length) ? result[0].title : this.ucFirst(slug.replace(/_/g, ' '));
    },
    ucFirst(text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
  },
  beforeCreate() {
    this.$options.filters.translate = this.$options.filters.translate.bind(this)
  },
  filters: {
    translate: function (value) {
      if (!value) {
        return '';
      }

      return this.Translate(value);
    }
  }
});
