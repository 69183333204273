/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import axios from 'axios';
import * as qs from 'qs';

// noinspection DuplicatedCode
export default new Vuex.Store({
  state: {
    ajaxUrl: window ? window.ajaxurl : global.ajaxurl,
    options: window.options,
    menus: window.menus,
    languages: window.languages,
    homeUrl: window.siteUrl,
    siteName: window.siteName,
    privacyPage: window.privacyPage,
    is_categories_visible: false
  },
  mutations: {
    setter(state, object) {
      Object.entries(object).forEach(([key, value]) => {
        Vue.set(state, key, value);
      });
    },
  },
  actions: {
    sendRequest({commit, state}, requestBody) {
      return axios.post(state.ajaxUrl, qs.stringify(requestBody));
    },
    setupData({state, commit, dispatch}, element) {
      Object.keys(state).forEach(key => {
        if (element.dataset.hasOwnProperty(key)) {
          let data = {};

          try {
            data[key] = JSON.parse(element.dataset[key]);
          } catch (e) {
            data[key] = element.dataset[key];
          }

          commit('setter', data)
        }
      });
    },
  },
  getters: {}
});
