<template v-if="isVisible || isThisVisible">
  <div class="dropdown-menu-box" :class="{'is-visible': isVisible || isThisVisible}"
       :style="{ left: left + 'px' }"
       @mouseover="showThis"
       @mouseleave="hideThis">
    <div class="dropdown-menu-box__item">
      <ul class="nav nav-dropdown menu">
        <menu-item v-for="item in menu" :key="item.term_id" :item="item"
                   :level="`second`" :hasCaret="item.children.length !== 0"
                   :is-highlighted="isParHighlighted === item.term_id"
                   @mouseOverHandler="mouseOver(item)"/>
      </ul>
    </div>
    <div class="dropdown-menu-box__item">
      <ul class="nav nav-dropdown-inner menu">
        <menu-item v-for="item in menuInner" :key="item.term_id" :item="item"
                   :level="`third`"
                   @mouseOverHandler="setHighlighted(item)"
                   @mouseLeaveHandler="setUnHighlighted()"/>
      </ul>
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem';

export default {
  name: "DropdownMenu",
  components: {
    MenuItem,
  },
  props: {
    menu: Array,
    isVisible: Boolean,
    isDesktop: Boolean,
    left: Number
  },
  data() {
    return {
      menuInner: [],
      isThisVisible: false,
      isParHighlighted: '',
    }
  },
  computed: {
    childMenu(menu) {
      return Object.values(menu.children).map((value) => {
        return value;
      })
    },
  },
  mounted() {
    this.defaultMenuInner();
  },
  methods: {
    defaultMenuInner() {
      const menu = this.menu.find(m => m.children.length !== 0)
      this.menuInner = Object.values(menu.children).map((value) => value);
      this.isParHighlighted = this.menuInner[0].parent;
    },
    mouseOver(item) {
      if (item.children.length !== 0) {
        this.menuInner = Object.values(item.children).map((value) => value);
        this.isParHighlighted = '';
      } else {
        this.menuInner = [];
      }
    },
    setHighlighted(item) {
      this.isParHighlighted = item.parent;
    },
    setUnHighlighted() {
      this.isParHighlighted = '';
    },
    showThis() {
      this.$emit('mouseOverHandler');
      this.isThisVisible = true;
    },
    hideThis() {
      this.$emit('mouseLeaveHandler');
      this.isThisVisible = false;
      this.defaultMenuInner();
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/setup";

.dropdown-menu-box {
  position: fixed;
  top: 80px;
  z-index: 100;
  width: 919px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: $c-white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  opacity: 0;
  pointer-events: none;
  transform: translateX(-200px);
  @include anim(opacity, .3s, ease);

  @include bp(1200) {
    top: 82px
  }

  &.is-visible {
    opacity: 1;
    pointer-events: all;
  }

  &__item {
    &:first-child {
      position: relative;
      padding: 21px 20px 62px 80px;

      &::after {
        @include pseudo();
        top: 21px;
        right: 0;
        width: 1px;
        height: calc(100% - 73.45px);
        background-color: rgba(196, 196, 196, 0.5);
      }

      .item {
        &.is-active,
        &.is-highlighted {
          color: $c-blue;

          .item__content {
            border-bottom-color: $c-blue;
          }

          .icon {
            transform: rotate(-90deg);

            svg {
              path {
                stroke: $c-blue;
              }
            }
          }
        }
      }
    }

    &:nth-child(2) {
      padding: 21px 80px 62px 20px;

      .nav {
        height: 100%;
      }

      .item {
        height: fit-content;

        &:last-child {
          flex-grow: 1;
          height: 100%;
        }

        &__content {
          &:hover {
            color: $c-blue;

            .item__content {
              border-bottom-color: $c-blue;
            }
          }
        }
      }
    }
  }

  .nav {
    .item {
      width: 100%;
      color: $c-black;
      @include anim();

      &__content {
        padding: 24px 0 8px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(196, 196, 196, 0.5);
      }

      .icon {
        margin-left: 20px;
        transform: rotate(0);
        @include anim();
      }
    }
  }
}
</style>
