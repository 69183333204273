var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.url
    ? _c("img", { attrs: { src: _vm.url, alt: _vm.name } })
    : _c("span", [_vm._v(_vm._s(_vm.name))])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }