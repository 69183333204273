<template>
  <form class="form" :class="getClass()">
    <div class="form__item form__field" v-if="fields.includes('name')">
      <span class="form__error" v-if="$v.form.name.$error">{{ getErrorMessage($v.form.name) }}</span>
      <input type="text"
             :class="{
                      filled: form.name.length,
                      error: $v.form.name.$error,
                   }"
             v-model="form.name"
             :placeholder="this.Translate('Name')" />
    </div>
    <div class="form__item form__field" v-if="fields.includes('email')">
      <span class="form__error" v-if="$v.form.email.$error">{{ getErrorMessage($v.form.email) }}</span>
      <input type="email"
             :class="{
                      filled: form.email.length,
                      error: $v.form.email.$error,
                   }"
             v-model="form.email"
             :placeholder="this.Translate('Email')" />
    </div>
    <div class="form__item form__field" v-if="fields.includes('phone')">
      <span class="form__error" v-if="$v.form.phone.$error">{{ getErrorMessage($v.form.phone) }}</span>
      <input type="tel"
             :class="{
                      filled: form.phone.length,
                      error: $v.form.phone.$error,
                   }"
             v-model="form.phone"
             v-imask="mask"
             :placeholder="this.Translate('Phone')" />
    </div>
    <div class="form__item form__field" v-if="fields.includes('model')">
      <span class="form__error" v-if="$v.form.model.$error">{{ getErrorMessage($v.form.model) }}</span>
      <input type="text"
             :class="{
                      filled: form.model.length,
                      error: $v.form.model.$error,
                   }"
             v-model="form.model"
             :placeholder="this.Translate('Model')" />
    </div>
    <div class="form__item form__field" v-if="fields.includes('message')">
      <span class="form__error" v-if="$v.form.message.$error">{{ getErrorMessage($v.form.message) }}</span>
      <!--suppress HtmlUnknownAttribute -->
      <textarea :class="{
                      filled: form.message.length,
                      error: $v.form.message.$error,
                   }"
                v-model="form.message"
                :placeholder="this.Translate('Message')"></textarea>
    </div>

    <div class="form__item form__button">
      <button type="submit"
              @click="submit"
              :class="buttonClassName"
              class="btn-theme btn-theme_transparent">
        <span class="name">{{ formButton }}</span>
        <span class="icon icon_arrow-right">
          <arrow-right-svg />
        </span>
      </button>
    </div>
  </form>
</template>

<script>
import {minLength, required, email} from "vuelidate/lib/validators";
import ArrowRightSvg from '../../icons/arrow-right.svg';

/**
 * @property {object} $v
 */
export default {
  name: "BaseForm",
  components: {
    ArrowRightSvg
  },
  props: {
    button: String,
    buttonClassName: String,
    isColumn: false,
    isRow: false,
    isWhite: false,
    isBlue: false,
    hideText: Boolean,
    fields: {
      type: Array,
      default: function () {
        return ['name', 'email', 'phone'];
      }
    }
  },
  data() {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        model: '',
        message: '',
      },
      loading: false,
      success: false,
      mask: {
        mask: '+38 (\\000) 000-00-00',
        lazy: true,
      },
    }
  },
  validations() {
    let valid = {
      form: {},
    }

    this.fields.forEach(field => {
      switch (field) {
        case 'name':
          valid.form[field] = {
            required,
            minLength: minLength(3)
          };
          break;
        case 'email':
          valid.form[field] = {
            required,
            email,
          };
          break;
        default:
          valid.form[field] = {
            required,
          };
          break;
      }
    });

    return valid;
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    formButton() {
      return this.button || this.Translate('Send');
    },
  },
  methods: {
    getClass() {
      return {
        'is-column': this.isColumn,
        'is-row': this.isRow,
        'is-white': this.isWhite,
        'is-blue': this.isBlue,
      }
    },
    submit(event) {
      event.preventDefault();

      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendForm();
      }
      // this.success = true;
      // this.$emit('success');
    },
    sendForm() {
      let data = {
        action: 'sendCallback',
        form: this.form,
      };

      if (!this.loading) {
        this.loading = true;
        return this.$store.dispatch('sendRequest', data).then(res => {
          // console.log(res);
          this.loading = false;

          if (res.data.success && res.data.data.result) {
            this.success = true;

            this.$emit('success', true);
          }
        });
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../styles/setup";

.form {
  $this: &;

  width: 100%;
  display: grid;

  &.is-column {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;

    #{$this}__button {
      margin-top: 20px;

      @include bp(768) {
        margin-top: 42px;
      }
    }

    & > * {
      width: 100%;
    }
  }

  &.is-row {
    grid-template-columns: 1fr;
    grid-row-gap: 37px;

    @include bp(1200) {
      grid-template-columns: repeat(2, 1fr) auto;
      grid-column-gap: 15px;
      grid-row-gap: 0;
    }

    #{$this}__button {
      margin: 0 auto;
    }
  }

  &.is-blue {
    #{$this}__field {
      input,
      textarea {
        color: rgba(0, 0, 0, .5);
        border-color: $c-blue;
      }
    }
  }

  &.is-white {
    #{$this}__field {
      input,
      textarea {
        color: $c-white;
        border-color: $c-white;
      }
    }
  }

  &__error {
    color: $c-red;
  }

  &__field {
    input,
    textarea {
      padding: 0 15px;
      width: 100%;
      font-size: 16px;
      line-height: 20px;
      background-color: transparent;
      border: 1px solid;

      .form.is-blue &.error,
      .form.is-white &.error {
        border-color: $c-red;
      }

      &::placeholder {
        color: currentColor;
        font-size: inherit;
      }
    }

    input {
      height: 52px;
    }

    textarea {
      padding-top: 16px;
      padding-bottom: 16px;
      height: 184px;
    }
  }

  &__button {
    .form-contacts & {
      width: 100%;

      .btn-theme {
        width: 100%;
      }
    }

    .btn-theme {
      padding: 14px 15px;
    }
  }
}
</style>
