import Vue from 'vue';
import store from './store/store';
import './store/translates';
import './store/directives';
import './store/mixin';

// Plugins
import {IMaskDirective} from 'vue-imask';
Vue.directive('imask', IMaskDirective);

import {validationMixin} from 'vuelidate';
Vue.mixin(validationMixin);

import Fragment from 'vue-fragment';
Vue.use(Fragment.Plugin);

// Components
import PageHeader from "./layout/PageHeader";
import CallBackForm from "./components/CallBackForm";
import ServicesForm from "./components/ServicesForm";
import ContactsForm from "./components/ContactsForm";
import BaseGmap from "./components/BaseGmap";
import PopupForm from "./components/PopupForm";

// Inits
let wrapperHeader = document.querySelector('[data-vue="pageHeader"]');
if (wrapperHeader) {
  let container = document.createElement('div');
  wrapperHeader.append(container);

  new Vue({
    el: wrapperHeader,
    render: h => h(PageHeader),
    store,
    // router,
  });
}


let wrapperCallback = document.querySelector('[data-vue="callback"]');
if (wrapperCallback) {
  let container = document.createElement('div');
  wrapperCallback.append(container);

  new Vue({
    el: wrapperCallback,
    render: h => h(CallBackForm),
    store,
    data: {
      class: wrapperCallback.className,
    }
  });
}

let wrapperServices = document.querySelector('[data-vue="servicesForm"]');
if (wrapperServices) {
  let container = document.createElement('div');
  wrapperServices.append(container);

  new Vue({
    el: wrapperServices,
    render: h => h(ServicesForm),
    store,
    // router,
  });
}

let wrapperContacts = document.querySelector('[data-vue="contactsForm"]');
if (wrapperContacts) {
  let container = document.createElement('div');
  wrapperContacts.append(container);

  new Vue({
    el: wrapperContacts,
    render: h => h(ContactsForm),
    store,
    // router,
  });
}

let wrapperMap = document.querySelector('[data-vue="gmap"]');
if (wrapperMap) {
  let container = document.createElement('div');
  wrapperMap.append(container);

  new Vue({
    el: wrapperMap,
    render: h => h(BaseGmap),
    store,
    // router,
  });
}

let wrapperPopupForm = document.createElement('div');
document.body.append(wrapperPopupForm);

new Vue({
  el: wrapperPopupForm,
  render: h => h(PopupForm),
  store,
  // router,
});
