<template>
  <img :src="url" :alt="name" v-if="url"/>
  <span v-else>{{ name }}</span>
</template>

<script>
export default {
  name: "Logo",
  props: {
    url: String,
    name: String,
  },
}
</script>

<style scoped>
</style>
