<template>
  <ul class="wpm-language-switcher switcher-list">
    <li v-for="lang in languages" :class="`${lang.current ? 'item-language-' + lang.code + ' is-active':
     'item-language-' + lang.code}`">
      <a v-if="!lang.current" :href="lang.href" :data-lang="lang.code">
        <span>{{lang.name}}</span>
      </a>
      <span v-else :data-lang="lang.code">
        <span>{{lang.name}}</span>
      </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "LangSwitch",
  props: {
    languages: Array,
  },
  computed: {
    thisUrl() {
      return window.location.href;
    },
  },
}
</script>

<style lang="scss">
@import "../../styles/setup";

.switcher-list {
  display: flex;
  align-items: center;

  & > * + * {
    margin: 0 0 0 6px;
  }

  .is-active {
    [data-lang] {
      font-weight: bold;
      color: $c-blue;
    }
  }

  [data-lang] {
    padding: 10px 5px;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: $c-black;
    @include anim();

    @include bp(1200) {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }

    @include hover {
      color: $c-blue;
    }
  }
}
</style>
