var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("section", { staticClass: "contact-form", class: _vm.getClass() }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "div",
        { staticClass: "contact-form__wrapper" },
        [
          _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
            !_vm.success
              ? _c(
                  "div",
                  { key: "form", staticClass: "contact-form__form" },
                  [
                    _vm.form.title
                      ? _c("h4", {
                          staticClass: "contact-form__title h3",
                          domProps: { innerHTML: _vm._s(_vm.form.title) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.form.subtitle
                      ? _c("p", { staticClass: "contact-form__text" }, [
                          _vm._v(_vm._s(_vm.form.subtitle)),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("base-form", {
                      attrs: {
                        button: _vm.form.button,
                        "button-class-name": `btn-theme_transparent-blue`,
                        "is-blue": true,
                        "is-column": true,
                        fields: _vm.fields,
                      },
                      on: {
                        success: function ($event) {
                          return _vm.onSuccess()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.success
              ? _c(
                  "div",
                  { key: "thanks", staticClass: "contact-form__success" },
                  [
                    _vm.form.thank_title
                      ? _c("h4", {
                          staticClass: "contact-form__title h3",
                          domProps: { innerHTML: _vm._s(_vm.form.thank_title) },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("p", {
                      staticClass: "contact-form__text",
                      domProps: { innerHTML: _vm._s(_vm.form.thank_text) },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }