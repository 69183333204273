import { render, staticRenderFns } from "./zoom-in.svg?vue&type=template&id=7453c974"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projects\\balenko.loc\\wp-content\\themes\\balenko\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7453c974')) {
      api.createRecord('7453c974', component.options)
    } else {
      api.reload('7453c974', component.options)
    }
    module.hot.accept("./zoom-in.svg?vue&type=template&id=7453c974", function () {
      api.rerender('7453c974', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/icons/zoom-in.svg"
export default component.exports