import Vue from 'vue';

import {sprintf, vsprintf} from 'sprintf-js';


Vue.mixin({
  data() {
    return {
      // windowWidth: window ? window.innerWidth : 0,
      // windowHeight: window ? window.innerHeight : 0,
      isTouchable: Boolean('ontouchstart' in window || navigator.maxTouchPoints),
      errorMessage: {
        required: 'This field is required',
        minLength: 'This field requires at least %d characters',
        email: 'Invalid email address',
        url: 'Invalid url address',
      }
    };
  },
  created () {
    if (window && document) {
      window.addEventListener('scroll', () =>
        document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`)
      )
    }
  },
  mounted() {
    // if (window) {
    //   this.$nextTick(function () {
    //     window.addEventListener('resize', this.getWindowWidth);
    //     window.addEventListener('resize', this.getWindowHeight);
    //     //Init
    //     this.getWindowWidth();
    //     this.getWindowHeight();
    //   });
    //   // refresh on top
    //   if ('scrollRestoration' in window.history) {
    //     window.history.scrollRestoration = "manual";
    //   }
    // }
    // if targets don't exist
    // this.$gsap.config({ nullTargetWarn: false });
  },
  methods: {
    sprintf: sprintf,
    vsprintf: vsprintf,
    getErrorMessage(errors) {
      if (errors.$error) {
        let type = false;

        Object.entries(errors).find(([key, value]) => {
          // console.log(key);
          if (!key.startsWith('$') && !value && !type) {
            type = key;
          }
        });
        let message = this.errorMessage[type];

        switch (type) {
          case 'minLength':
            return this.sprintf(message, errors.$params.minLength.min)
          default:
            return message;
        }
      }
    },
    deviceDetector() {
      this.isTouchable ? document.body.classList.add('touch') : document.body.classList.add('no-touch');
    },
    convertStringToDate(string) {
      return new Date(this.stringToIso(string))
    },
    stringToIso(string) {
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;

      return string.replace(pattern, '$3-$2-$1')
    },
    currentPath() {
      return location.pathname;
    },
    parseGetParams(get_param) {
      if (window) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(get_param);
      } else {
        return {};
      }
    },
    // getWindowWidth() {
    //   if (document) {
    //     this.windowWidth = document.documentElement.clientWidth;
    //     const vw = this.windowWidth * 0.01;
    //     document.documentElement.style.setProperty("--vw", `${vw}px`);
    //   }
    // },
    // getWindowHeight() {
    //   if (document) {
    //     this.windowHeight = document.documentElement.clientHeight;
    //     const vh = this.windowHeight * 0.01;
    //     document.documentElement.style.setProperty("--vh", `${vh}px`);
    //   }
    // },
    scrollHide() {
      if (document && window) {
        const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
        const body = document.body;
        body.classList.add('no-scroll');
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}`;
      }
    },
    scrollShow() {
      if (document && window) {
        const body = document.body;
        const scrollY = body.style.top;
        body.classList.remove('no-scroll');
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    },
  }
});
