export const mapStyles = [
  {
    featureType: "all",
    stylers: [{"color": "#757575"}]
  },
  {
    elementType: "geometry",
    stylers: [{color: "#f3f3f3"}]
  },
  {
    elementType: "labels.text.fill",
    stylers: [{color: "#757575"}]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [{color: "#ffffff"}]
  },
  {
    elementType: "labels.icon",
    stylers: [{"color": "#8c8c8c"}]
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [{color: "#757575"}],
  },
  {
    featureType: "landscape.natural",
    elementType: "geometry",
    stylers: [{color: "#e5e5e5"}],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [{color: "#969696"}],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{color: "#757575"}],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{color: "#ffffff"}],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{color: "#e2e2e2"}],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{color: "#757575"}],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [{color: "#ffffff"}],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [{color: "#e2e2e2"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{color: "#ffffff"}],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{color: "#e2e2e2"}],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [{color: "#c6c6c6"}],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{color: "#848484"}],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        "lightness": 19
      },
      {
        "color": "#9b9a98"
      },
      {
        "weight": "1.00"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    featureType: "transit",
    elementType: "labels.text.stroke",
    stylers: [{color: "#757575"}]
  },
  {
    featureType: "transit",
    elementType: "labels.text.stroke",
    stylers: [{color: "#ffffff"}]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{
      visibility: 'off'
    }]
  }
]
