import { render, staticRenderFns } from "./DropdownMenu.vue?vue&type=template&id=3fcd392c&v-if=isVisible%20%7C%7C%20isThisVisible"
import script from "./DropdownMenu.vue?vue&type=script&lang=js"
export * from "./DropdownMenu.vue?vue&type=script&lang=js"
import style0 from "./DropdownMenu.vue?vue&type=style&index=0&id=3fcd392c&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Projects\\balenko.loc\\wp-content\\themes\\balenko\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3fcd392c')) {
      api.createRecord('3fcd392c', component.options)
    } else {
      api.reload('3fcd392c', component.options)
    }
    module.hot.accept("./DropdownMenu.vue?vue&type=template&id=3fcd392c&v-if=isVisible%20%7C%7C%20isThisVisible", function () {
      api.rerender('3fcd392c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/vue/components/DropdownMenu.vue"
export default component.exports