<template>
  <div class="services-form" :class="getClass()">
    <div class="services-form__wrapper">
      <transition name="fade" mode="out-in">
        <div v-if="!success" key="form" class="contact-form__form">
          <h4 class="services-form__title" v-if="form.title" v-html="form.title"></h4>
          <base-form :button="form.button"
                     :button-class-name="`btn-theme_transparent-blue`"
                     :is-blue="true"
                     :is-column="true"
                     :fields="fields"
                     v-on:success="onSuccess"
          ></base-form>
          <!--suppress JSUnresolvedVariable -->
          <p class="services-form__text">
            {{ policy.text }} <a :href="privacyPage.url">{{ policy.link }}</a>
          </p>
        </div>

        <div v-if="success" key="thanks" class="services-form__success">
          <!--suppress JSUnresolvedVariable -->
          <h4 class="services-form__title" v-if="form.thank_title" v-html="form.thank_title"></h4>
          <!--suppress JSUnresolvedVariable -->
          <p class="services-form__text" v-html="form.thank_text"></p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import BaseForm from "./BaseForm";
import CheckSvg from '../../icons/check.svg';

export default {
  name: "ServicesForm",
  components: {
    BaseForm,
    CheckSvg,
  },
  data() {
    return {
      success: false,
      fields: ['name', 'email', 'phone', 'model', 'message'],
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    form() {
      return this.options.forms.services;
    },
    policy() {
      return options.forms.policy;
    },
    privacyPage() {
      return this.$store.state.privacyPage;
    },
  },
  methods: {
    getClass() {
      return {
        'has-form': !this.success,
        'has-success': this.success,
      }
    },
    onSuccess() {
      this.success = true;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push ({
        'event': 'formSuccess',
        'formName': this.$options.name,
      });

      setTimeout(() => {
        this.toggle();
      }, 3000);
    },
  }
}
</script>

<style lang="scss">
@import "../../styles/setup";

.services-form {
  margin: 0 -20px;
  padding: 69px 20px;
  min-height: 100%;
  background-color: $c-white;

  @include bp(768) {
    margin: 0 -50px;
    padding: 56.22px 50px 56px;
  }

  @include bp(1200) {
    margin: 0 -10px 0 0;
  }

  @include bp(1440) {
    margin: -60px -60px 0 0;
    padding: 60px;
    min-height: calc(100% + 60px);
  }

  &.has-success {
    display: flex;
    justify-content: center;
    align-items: center;

    .services-form__title {
      margin-left: auto;
      margin-right: auto;
      max-width: 519px;
    }

    .services-form__text {
      margin: 0 auto;
      max-width: 431.39px;
      text-align: center;
    }
  }

  &__title {
    margin-bottom: 30px;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    text-align: center;

    @include bp(768) {
      font-size: 28px;
      line-height: 45px;
      letter-spacing: 0.02em;
    }
  }

  &__text {
    margin-top: 20px;
    text-align: center;

    @include bp(768) {
      text-align: left;
    }

    span,
    a {
      display: inline-block;
      text-decoration: underline;
      color: $c-blue;
    }
  }

  .form.is-column {
    .form__button {
      margin-top: 0;
    }
  }

  .btn-theme {
    width: 100%;

    @include bp(768) {
      width: fit-content;
    }
  }
}
</style>
