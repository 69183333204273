var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("form", { staticClass: "form", class: _vm.getClass() }, [
    _vm.fields.includes("name")
      ? _c("div", { staticClass: "form__item form__field" }, [
          _vm.$v.form.name.$error
            ? _c("span", { staticClass: "form__error" }, [
                _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.name))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            class: {
              filled: _vm.form.name.length,
              error: _vm.$v.form.name.$error,
            },
            attrs: { type: "text", placeholder: this.Translate("Name") },
            domProps: { value: _vm.form.name },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "name", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.fields.includes("email")
      ? _c("div", { staticClass: "form__item form__field" }, [
          _vm.$v.form.email.$error
            ? _c("span", { staticClass: "form__error" }, [
                _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.email))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.email,
                expression: "form.email",
              },
            ],
            class: {
              filled: _vm.form.email.length,
              error: _vm.$v.form.email.$error,
            },
            attrs: { type: "email", placeholder: this.Translate("Email") },
            domProps: { value: _vm.form.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "email", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.fields.includes("phone")
      ? _c("div", { staticClass: "form__item form__field" }, [
          _vm.$v.form.phone.$error
            ? _c("span", { staticClass: "form__error" }, [
                _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.phone))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.phone,
                expression: "form.phone",
              },
              {
                name: "imask",
                rawName: "v-imask",
                value: _vm.mask,
                expression: "mask",
              },
            ],
            class: {
              filled: _vm.form.phone.length,
              error: _vm.$v.form.phone.$error,
            },
            attrs: { type: "tel", placeholder: this.Translate("Phone") },
            domProps: { value: _vm.form.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "phone", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.fields.includes("model")
      ? _c("div", { staticClass: "form__item form__field" }, [
          _vm.$v.form.model.$error
            ? _c("span", { staticClass: "form__error" }, [
                _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.model))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.model,
                expression: "form.model",
              },
            ],
            class: {
              filled: _vm.form.model.length,
              error: _vm.$v.form.model.$error,
            },
            attrs: { type: "text", placeholder: this.Translate("Model") },
            domProps: { value: _vm.form.model },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "model", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.fields.includes("message")
      ? _c("div", { staticClass: "form__item form__field" }, [
          _vm.$v.form.message.$error
            ? _c("span", { staticClass: "form__error" }, [
                _vm._v(_vm._s(_vm.getErrorMessage(_vm.$v.form.message))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.message,
                expression: "form.message",
              },
            ],
            class: {
              filled: _vm.form.message.length,
              error: _vm.$v.form.message.$error,
            },
            attrs: { placeholder: this.Translate("Message") },
            domProps: { value: _vm.form.message },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "message", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form__item form__button" }, [
      _c(
        "button",
        {
          staticClass: "btn-theme btn-theme_transparent",
          class: _vm.buttonClassName,
          attrs: { type: "submit" },
          on: { click: _vm.submit },
        },
        [
          _c("span", { staticClass: "name" }, [_vm._v(_vm._s(_vm.formButton))]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "icon icon_arrow-right" },
            [_c("arrow-right-svg")],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }