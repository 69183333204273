var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("fragment", [
    _c("div", { ref: "googleMap", staticClass: "google-map" }),
    _vm._v(" "),
    !_vm.isTouchable
      ? _c("div", { staticClass: "zoom-wrap" }, [
          _c(
            "div",
            { ref: "zoomIn", staticClass: "btn-zoom btn-zoom_in" },
            [_c("zoom-in-svg")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { ref: "zoomOut", staticClass: "btn-zoom btn-zoom_out" },
            [_c("zoom-out-svg")],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }