var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "results" }, [
    _c(
      "ul",
      { staticClass: "results__list results-list" },
      _vm._l(_vm.results.slice(0, 4), function (item) {
        return _c(
          "li",
          { key: item.id, staticClass: "results-list__item" },
          [_c("search-results-item", { attrs: { item: item } })],
          1
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "results__button" }, [
      _c("a", { staticClass: "btn-more", attrs: { href: _vm.searchLink } }, [
        _vm._v(_vm._s(_vm._f("translate")("show_all")) + "\n      "),
        _c(
          "span",
          { staticClass: "icon icon_arrow" },
          [_c("arrow-right-svg")],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }