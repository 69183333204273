<template>
  <li :class="{'item is-active': isActive, 'item': !isActive,
     'is-highlighted': isHighlighted || isThisHighlighted,
     'menu-item': level === 'first',
     'second-menu-item': level === 'second',
     'third-menu-item': level === 'third',
     'has-children': hasChildren}"
      @click="clickHandler"
      @mouseover="mouseOverHandler" @mouseleave="mouseLeaveHandler">
    <template>
      <a :href="item.url ? item.url : siteUrl + item.slug + '/'" v-if="!hasChildren" class="item__content">
        <span class="icon icon_line" v-if="hasLine"></span>
        <span class="name">{{ item.title || item.name }}</span>
        <span class="icon icon_caret" v-if="hasCaret">
          <caret-svg/>
        </span>
      </a>
      <a :href="item.url ? item.url : siteUrl + item.slug + '/'" v-else class="item__content">
        <span class="icon icon_line" v-if="hasLine"></span>
        <span class="name">{{ item.title }}</span>
        <span class="icon icon_caret" v-if="hasCaret && hasChildren">
          <caret-svg/>
        </span>
      </a>
    </template>

    <slot></slot>
  </li>
</template>

<script>
import CaretSvg from '../../icons/caret.svg';

export default {
  name: "MenuItem",
  components: {
    CaretSvg,
  },
  props: {
    item: Object,
    className: String,
    level: String,
    hasLine: false,
    hasCaret: false,
    hasChildren: false,
    isHighlighted: false,
  },
  data() {
    return {
      isThisHighlighted: false,
    }
  },
  computed: {
    siteUrl() {
      return document.location.origin + '/';
    },
    thisUrl() {
      return window.location.href;
    },
    isActive() {
      return this.thisUrl === this.item.url
    }
  },
  methods: {
    clickHandler() {
      this.$emit('clickHandler');
    },
    mouseOverHandler() {
      this.$emit('mouseOverHandler');
      this.isThisHighlighted = true;
    },
    mouseLeaveHandler() {
      this.$emit('mouseLeaveHandler');
      this.isThisHighlighted = false;
    }
  },
}
</script>

<style scoped>
</style>
