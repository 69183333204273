var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
        _vm.show
          ? _c(
              "div",
              {
                staticClass: "popup__overlay",
                on: {
                  click: function ($event) {
                    if ($event.target !== $event.currentTarget) return null
                    return _vm.toggle.apply(null, arguments)
                  },
                },
              },
              [
                _c("div", { staticClass: "popup__wrapper" }, [
                  _c(
                    "div",
                    {
                      ref: "popup",
                      staticClass: "popup-form",
                      attrs: { id: "consultation-popup" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "popup-form__close js-popup-close",
                          on: { click: _vm.toggle },
                        },
                        [_c("cross-svg")],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "popup-form__wrapper js-popup-wrapper" },
                        [
                          _c(
                            "div",
                            { staticClass: "popup-form__head head" },
                            [
                              _c("img", {
                                staticClass: "popup-form__img",
                                attrs: { src: _vm.image, alt: _vm.title },
                              }),
                              _vm._v(" "),
                              _c("logo-svg"),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "transition",
                            { attrs: { name: "fade", mode: "out-in" } },
                            [
                              !_vm.success
                                ? _c(
                                    "div",
                                    {
                                      key: "form",
                                      staticClass: "popup-form__form",
                                    },
                                    [
                                      _c("p", {
                                        staticClass: "popup-form__subtitle",
                                        domProps: {
                                          innerHTML: _vm._s(_vm.title),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("base-form", {
                                        attrs: {
                                          button:
                                            this.Translate("Request a call"),
                                          "button-class-name": `btn-theme_transparent-blue btn-theme_wild`,
                                          "is-blue": true,
                                          "is-column": true,
                                          fields: _vm.fields,
                                        },
                                        on: { success: _vm.onSuccess },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        { staticClass: "popup-form__text" },
                                        [
                                          _vm._v(_vm._s(_vm.policy.text) + " "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: _vm.privacyPage.url,
                                              },
                                            },
                                            [_vm._v(_vm._s(_vm.policy.link))]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.success
                                ? _c(
                                    "div",
                                    {
                                      key: "thanks",
                                      staticClass: "popup-form__success",
                                    },
                                    [
                                      _vm.thank_title
                                        ? _c("h4", {
                                            staticClass: "popup-form__title",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.thank_title
                                              ),
                                            },
                                          })
                                        : _vm._e(),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }