var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "site-search__outer" }, [
    _c(
      "div",
      {
        staticClass: "site-search",
        class: { "is-visible": _vm.isSearchVisible },
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "site-search__form" }, [
            _c(
              "label",
              {
                staticClass: "input-wrap",
                class: {
                  "is-active":
                    _vm.noResults || (_vm.results && _vm.results.length),
                },
              },
              [
                _c(
                  "span",
                  { staticClass: "icon icon_search" },
                  [_c("search-svg")],
                  1
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search",
                    },
                  ],
                  attrs: { type: "text" },
                  domProps: { value: _vm.search },
                  on: {
                    keyup: _vm.getSearchResults,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.search = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "icon icon_cross",
                    on: { click: _vm.hideSearch },
                  },
                  [_c("cross-svg")],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _vm.search.length
      ? _c(
          "div",
          { staticClass: "site-search-results", class: _vm.getClass() },
          [
            _c("div", { staticClass: "container" }, [
              _vm.results && _vm.results.length === 0
                ? _c(
                    "div",
                    { staticClass: "site-search-results__empty empty" },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getNoResult(_vm.search)) +
                          "\n      "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.results && _vm.results.length
                ? _c(
                    "div",
                    {
                      staticClass: "site-search-results__full full",
                      style: { height: _vm.height, maxHeight: _vm.maxHeight },
                    },
                    [
                      _c("div", { staticClass: "full__title" }, [
                        _vm._v(
                          _vm._s(_vm._f("translate")("You search")) +
                            ':  "' +
                            _vm._s(_vm.search) +
                            '"'
                        ),
                      ]),
                      _vm._v(" "),
                      _c("search-results", {
                        attrs: { search: _vm.search, results: _vm.results },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }