<template>
  <div class="results">
    <ul class="results__list results-list">
      <li class="results-list__item" v-for="item in results.slice(0, 4)" :key="item.id">
        <search-results-item :item="item"/>
      </li>
    </ul>

    <div class="results__button">
      <a class="btn-more" :href="searchLink">{{ 'show_all' | translate }}
        <span class="icon icon_arrow">
        <arrow-right-svg />
      </span>
      </a>
    </div>
  </div>
</template>

<script>
import SearchResultsItem from './SearchResultsItem';
import ArrowRightSvg from '../../icons/arrow-right.svg';

export default {
  name: "SearchResults",
  components: {
    SearchResultsItem,
    ArrowRightSvg,
  },
  props: {
    results: Array,
    search: String,
  },
  computed: {
    searchLink() {
      return window.location.origin + `?s=${this.search}&post_type=product`;
    }
  },
}
</script>

<style lang="scss">
@import "../../styles/setup";

.results {
  padding-right: 8.23px;
  flex-grow: 1;
  overflow-y: auto;

  .results-list {
    margin-bottom: 56px;

    @include bp(1200) {
      margin-bottom: 32px;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 18px;

        @include bp(768) {
          margin-bottom: 24px;
        }
      }
    }
  }

  &__button {
    border-bottom: 1px solid rgba(196, 196, 196, 0.5);

    .btn-more {
      padding-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: $c-blue;

      @include bp(768) {
        width: fit-content;
        justify-content: flex-start;
      }

      .icon {
        margin-left: 32px;
      }

      svg {
        width: 21px;
        height: 17px;

        path {
          fill: currentColor;
        }
      }
    }
  }
}
</style>
