var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dropdown-menu-box",
      class: { "is-visible": _vm.isVisible || _vm.isThisVisible },
      style: { left: _vm.left + "px" },
      on: { mouseover: _vm.showThis, mouseleave: _vm.hideThis },
    },
    [
      _c("div", { staticClass: "dropdown-menu-box__item" }, [
        _c(
          "ul",
          { staticClass: "nav nav-dropdown menu" },
          _vm._l(_vm.menu, function (item) {
            return _c("menu-item", {
              key: item.term_id,
              attrs: {
                item: item,
                level: `second`,
                hasCaret: item.children.length !== 0,
                "is-highlighted": _vm.isParHighlighted === item.term_id,
              },
              on: {
                mouseOverHandler: function ($event) {
                  return _vm.mouseOver(item)
                },
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dropdown-menu-box__item" }, [
        _c(
          "ul",
          { staticClass: "nav nav-dropdown-inner menu" },
          _vm._l(_vm.menuInner, function (item) {
            return _c("menu-item", {
              key: item.term_id,
              attrs: { item: item, level: `third` },
              on: {
                mouseOverHandler: function ($event) {
                  return _vm.setHighlighted(item)
                },
                mouseLeaveHandler: function ($event) {
                  return _vm.setUnHighlighted()
                },
              },
            })
          }),
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }