var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "services-form", class: _vm.getClass() }, [
    _c(
      "div",
      { staticClass: "services-form__wrapper" },
      [
        _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
          !_vm.success
            ? _c(
                "div",
                { key: "form", staticClass: "contact-form__form" },
                [
                  _vm.form.title
                    ? _c("h4", {
                        staticClass: "services-form__title",
                        domProps: { innerHTML: _vm._s(_vm.form.title) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("base-form", {
                    attrs: {
                      button: _vm.form.button,
                      "button-class-name": `btn-theme_transparent-blue`,
                      "is-blue": true,
                      "is-column": true,
                      fields: _vm.fields,
                    },
                    on: { success: _vm.onSuccess },
                  }),
                  _vm._v(" "),
                  _c("p", { staticClass: "services-form__text" }, [
                    _vm._v("\n          " + _vm._s(_vm.policy.text) + " "),
                    _c("a", { attrs: { href: _vm.privacyPage.url } }, [
                      _vm._v(_vm._s(_vm.policy.link)),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.success
            ? _c(
                "div",
                { key: "thanks", staticClass: "services-form__success" },
                [
                  _vm.form.thank_title
                    ? _c("h4", {
                        staticClass: "services-form__title",
                        domProps: { innerHTML: _vm._s(_vm.form.thank_title) },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("p", {
                    staticClass: "services-form__text",
                    domProps: { innerHTML: _vm._s(_vm.form.thank_text) },
                  }),
                ]
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }