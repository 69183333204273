<template>
  <section class="contact-form" :class="getClass()">
    <div class="container">
      <div class="contact-form__wrapper">
        <transition name="fade" mode="out-in">
          <div v-if="!success" key="form" class="contact-form__form">
            <h4 class="contact-form__title h3" v-if="form.title" v-html="form.title"></h4>
            <p class="contact-form__text" v-if="form.subtitle">{{ form.subtitle }}</p>
            <base-form :button="form.button"
                       :button-class-name="`btn-theme_transparent-blue`"
                       :is-blue="true"
                       :is-column="true"
                       :fields="fields"
                       v-on:success="onSuccess()"
            ></base-form>

            <!--suppress JSUnresolvedVariable -->
<!--            <p class="contact-form__text" v-html="options.forms.policy"></p>-->
          </div>

          <div v-if="success" key="thanks" class="contact-form__success">
<!--            <div class="icon icon_check">-->
<!--              <check-svg />-->
<!--            </div>-->
            <!--suppress JSUnresolvedVariable -->
            <h4 class="contact-form__title h3" v-if="form.thank_title" v-html="form.thank_title"></h4>
            <!--suppress JSUnresolvedVariable -->
            <p class="contact-form__text" v-html="form.thank_text"></p>
          </div>
        </transition>
      </div>
    </div>
  </section>
</template>

<script>
import BaseForm from "./BaseForm";
import CheckSvg from '../../icons/check.svg';

export default {
  name: "ContactsForm",
  components: {
    BaseForm,
    CheckSvg,
  },
  data() {
    return {
      success: false,
      fields: ['name', 'email', 'phone', 'message'],
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    form() {
      // noinspection JSUnresolvedVariable
      return this.options.forms.contacts;
    }
  },
  methods: {
    getClass() {
      return {
        'has-form': !this.success,
        'has-success': this.success,
      }
    },
    onSuccess() {
      this.success = true;

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push ({
        'event': 'formSuccess',
        'formName': this.$options.name,
      });

      setTimeout(() => {
        this.success = false;
      }, 3000);
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../styles/setup";

.form-contacts {
  .contact-form {
    margin: 0 -20px;
    padding: 50px 20px;
    width: calc(100% + 40px);
    background-color: $c-white;

    @include bp(768) {
      margin: 0 -50px;
      padding: 58.5px 50px;
      width: calc(100% + 100px);
    }

    @include bp(1440) {
      margin: 0 0 0 -50px;
      padding: 60px;
      width: calc(100% + 50px);
    }

    &.has-form {
      .contact-form__text {
        margin-bottom: 30px;

        @include bp(1440) {
          margin-bottom: 40px;
        }
      }
    }

    &.has-success {
      .contact-form__text {
        margin-right: auto;
        margin-left: auto;
        max-width: 431.39px;
      }
    }

    .container {
      padding: 0;
      max-width: unset;
    }

    &__title,
    &__text {
      text-align: center;
    }

    &__title {
      margin-bottom: 16px;
    }
  }
}
</style>
