var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "result" }, [
    _vm.item.type && _vm.item.type !== "product"
      ? _c("div", { staticClass: "result__category" }, [
          _vm._v(_vm._s(_vm.item.type)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "result__link",
        class: _vm.getClass(),
        attrs: { href: _vm.item.url },
      },
      [
        _c("div", { staticClass: "result__content" }, [
          _vm.item.image
            ? _c("div", { staticClass: "result__img" }, [
                _c("img", {
                  attrs: {
                    src: _vm.item.image,
                    alt: _vm.item.title ? _vm.item.title : "Balenko",
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.title
            ? _c("div", { staticClass: "result__name" }, [
                _vm._v(_vm._s(_vm.item.title)),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "result__icon icon icon_arrow" },
          [_c("arrow-right-svg")],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }