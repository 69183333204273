<template>
  <div class="mobile-menu" :class="{'is-visible': isVisible}">
    <div class="container">
      <div class="mobile-menu__wrapper">
        <ul class="mobile-menu__nav nav nav-burger menu">
          <menu-item v-for="item in menu" :key="item.term_id" :item="item"
                     level="first" :has-children="item.classes.includes(catalogClass)">
            <template v-if="item.classes.includes(catalogClass)">
              <ul class="subnav">
                <menu-item v-for="itemInner in menuInner" :key="itemInner.term_id" :item="itemInner"
                           level="second" :hasLine="true"/>
              </ul>
            </template>
          </menu-item>
        </ul>

        <div class="mobile-menu__phones">
          <a v-for="item in phones"
             :href="phoneLink(item.phone)"
             class="header__phone"
             v-if="item.phone">

              <span class="icon icon_phone mobile">
                <phone-svg/>
              </span>
              <span class="name tablet">{{ item.phone }}</span>
          </a>
        </div>

        <div class="mobile-menu__switches" v-if="languages">
          <lang-switch :languages="languages"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuItem from './MenuItem';
import LangSwitch from './LangSwitch';
import PhoneSvg from "../../icons/phone.svg";

export default {
  name: "MobileMenu",
  components: {
    PhoneSvg,
    MenuItem,
    LangSwitch,
  },
  props: {
    menu: Array,
    menuInner: Array,
    languages: Array,
    isVisible: Boolean,
  },
  data() {
    return {
      isThisVisible: false,
      catalogClass: 'catalog-menu-item',
    }
  },
  computed: {
    options() {
      return this.$store.state.options;
    },
    phones() {
      return this.options.phones;
    },
  },
  methods: {
    phoneLink(phone) {
      let digits = phone.match(/\d+/g);
      let number = digits ? digits.join('') : phone;

      return 'tel:' + number;
    }
  }
}
</script>

<style lang="scss">
@import "../../styles/setup";

.mobile-menu {
  position: fixed;
  top: 60px;
  left: 0;
  z-index: 99;
  width: 100%;
  height: calc(100% - 60px);
  overflow-y: auto;
  background-color: $c-white;

  @include bp(768) {
    top: 80px;
    height: calc(100% - 80px);
  }

  &__wrapper {
    padding: 20px 0 0;
  }

  &__nav {
    display: flex;
    flex-direction: column;

    &.nav {
      margin-bottom: 36px;

      @include bp(768) {
        margin-bottom: 32px;
      }
    }

    & > * + & {
      margin: 12px 0 0;

      @include bp(768) {
        margin: 16px 0 0;
      }
    }

    .menu-item {
      display: flex;
      font-weight: 600;
      color: $c-black;

      &.has-children {
        flex-direction: column;

        @include bp(1200) {
          flex-direction: row;
        }
      }

      &.is-active,
      &.is-highlighted {
        .name {
          &::before {
            transform: scaleX(1);
          }
        }
      }

      &.is-active {
        pointer-events: none;
      }

      & > .item__content {
        padding: 10px 0;
        font-size: 18px;
        line-height: 26px;
        text-transform: uppercase;

        @include bp(768) {
          font-size: 28px;
          line-height: 45px;
        }
      }

      .name {
        position: relative;
        display: flex;
        width: fit-content;

        &::before {
          @include pseudo();
          top: 100%;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $c-blue;
          transform: scaleX(0);
          transform-origin: 0 0;
          @include anim();
        }
      }
    }

    .second-menu-item {
      margin-bottom: 4px;

      & > .item__content {
        padding: 10px 0;
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;

        @include bp(768) {
          font-size: 16px;
          line-height: 24px;
        }

        .name {
          &::before {
            display: none;
          }
        }

        .icon {
          margin-right: 12px;
          display: block;
          width: 17px;
          height: 2px;
          background-color: $c-blue;

          @include bp(768) {
            margin-right: 24px;
            width: 26px;
          }
        }
      }
    }

    .subnav {
      @include bp(768) {
        margin: 16px 0;
      }
    }
  }

  &__phones {
    margin-bottom: 15px;

    a {
      font-size: 18px;

      &:not(:first-child) {
        margin-top: 10px;
      }

      .icon {
        margin-right: 5px;

        svg {
          width: 17px;
          height: auto;
          margin-top: -1px;
        }
      }
    }
  }

  &__switches {
    margin-top: auto;
    display: flex;
    justify-content: center;
  }
}
</style>
